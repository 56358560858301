<template>
  <v-card>
    <v-card-title>
      <span>Relatório</span>

      <v-spacer />

      <v-btn
        color="warning"
        class="mr-4"
        outlined
        :disabled="disableExportButton"
        @click="getPdf()"
      >
        <v-icon
          size="30"
          class="me-2"
        >
          {{ icons.mdiDownload }}
        </v-icon>
        <span v-if="!isLoadingExport"> Exportar </span>
        <v-progress-circular
          v-else
          color="warning"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>

      <div class="d-flex justify-end">
        <v-btn
          color="info"
          class="mr-4"
          data-test="button-search"
          outlined
          @click="openModal('filter')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiAccountSearch }}
          </v-icon>
          <span> Pesquisar </span>
        </v-btn>

        <v-btn
          class="mr-4"
          outlined
          @click="resetTable()"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>
          <span> Limpar dados </span>
        </v-btn>
      </div>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="isLoading"
      loading-text="Carregando dados..."
      sort-by="name"
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:foot>
        <tfoot>
          <tr>
            <th class="footer-center">
              <h4>
                TOTAL
              </h4>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th class="footer-center">
              <h4>
                {{ totalValues }}
              </h4>
            </th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </template>

      <template v-slot:item.value="{ item }">
        {{ formatBrazilianCurrency(item.value) }}
      </template>

      <template v-slot:item.dueDate="{ item }">
        {{ dateFormat(item.dueDate, (isDateWithoutTime = true)) }}
      </template>

      <template v-slot:item.emission="{ item }">
        {{ dateFormat(item.emission, (isDateWithoutTime = true)) }}
      </template>

      <template v-slot:item.movementDate="{ item }">
        {{ dateFormat(item.movementDate, (isDateWithoutTime = true)) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showFilter"
      width="700px"
    >
      <FilterStatement
        @filteredData="setTableData($event)"
        @close="showFilter = false"
      ></FilterStatement>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAccountSearch, mdiCalendar, mdiDownload, mdiFilterRemoveOutline,
} from '@mdi/js'
import FilterStatement from './FilterStatement.vue'

export default {
  components: {
    FilterStatement,
  },

  mixins: [formatters, messages],

  data() {
    return {
      rawData: [],
      itemsTable: [],
      totalValues: 0,

      headers: [
        {
          text: 'NOME',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DOCUMENTO',
          value: 'document',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FILIAL',
          value: 'fantasyName',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EMISSÃO',
          value: 'emission',
          sortable: false,
          align: 'center',
        },
        {
          text: 'ABERTO',
          value: 'open',
          sortable: false,
          align: 'center',
        },
        {
          text: 'PARCELA',
          value: 'value',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VENCIMENTO',
          value: 'dueDate',
          sortable: false,
          align: 'center',
        },
      ],

      isLoading: false,
      isLoadingExport: false,

      showFilter: false,
      disableExportButton: true,

      icons: {
        mdiCalendar, mdiDownload, mdiAccountSearch, mdiFilterRemoveOutline,
      },
    }
  },

  methods: {
    sumValues(items, field) {
      const hasField = items[0][field]

      if (hasField) {
        const result = items.reduce((sum, item) => sum + Number(item[field]), 0)

        return this.formatBrazilianCurrency(result)
      }

      return '0'
    },

    setTableData([data, headers, rawData]) {
      this.itemsTable = data
      this.headers = headers
      this.rawData = rawData
      this.totalValues = this.sumValues(data, 'value')

      this.disableExportButton = false
    },

    getPdf() {
      /** @type {{type: string}} */
      const [firstItem] = this.itemsTable
      const types = { duplicata: () => 'duplicate_report', valeFuncionario: () => 'vale_report' }

      if (firstItem.type in types) {
        const path = types[firstItem.type]()
        this.getBlobFile(path)

        return
      }

      console.error('The type does not exist')
    },

    async getBlobFile(path) {
      this.isLoadingExport = true
      const body = { data: this.rawData }

      await axiosIns
        .post(`api/v1/integration/typing_sheet/${path}`, body, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
          const pdfUrl = window.URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => { this.showMessage({ title: 'erro ao gerar PDF', text: error.message, icon: 'error' }) })
        .finally(() => {
          this.isLoadingExport = false
        })
    },

    openModal(type) {
      const modal = { filter: () => { this.showFilter = true } }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('Modal type does not exist in this.openModal()')
    },

    resetTable() {
      this.itemsTable = []
      this.totalValues = 0
    },
  },
}
</script>
<style scoped>
.footer-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
