import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Relatório")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"warning","outlined":"","disabled":_vm.disableExportButton},on:{"click":function($event){return _vm.getPdf()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),(!_vm.isLoadingExport)?_c('span',[_vm._v(" Exportar ")]):_c(VProgressCircular,{attrs:{"color":"warning","indeterminate":""}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"info","data-test":"button-search","outlined":""},on:{"click":function($event){return _vm.openModal('filter')}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountSearch)+" ")]),_c('span',[_vm._v(" Pesquisar ")])],1),_c(VBtn,{staticClass:"mr-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.resetTable()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_c('span',[_vm._v(" Limpar dados ")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados...","sort-by":"name"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',[_c('th',{staticClass:"footer-center"},[_c('h4',[_vm._v(" TOTAL ")])]),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"footer-center"},[_c('h4',[_vm._v(" "+_vm._s(_vm.totalValues)+" ")])]),_c('th'),_c('th')])])]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBrazilianCurrency(item.value))+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.dueDate, (_vm.isDateWithoutTime = true)))+" ")]}},{key:"item.emission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.emission, (_vm.isDateWithoutTime = true)))+" ")]}},{key:"item.movementDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.movementDate, (_vm.isDateWithoutTime = true)))+" ")]}}])}),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('FilterStatement',{on:{"filteredData":function($event){return _vm.setTableData($event)},"close":function($event){_vm.showFilter = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }